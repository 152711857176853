import React from 'react';

interface AuthorProps {
  author: {
    imageSrc: string;
    firstName: string;
    lastName: string;
  };
}

const Author: React.FC<AuthorProps> = props => {
  return (
    <div className="flex">
      <img
        className="w-10 rounded-full h-10 mr-2"
        src={props.author.imageSrc}
      />
      <p className="text-sm text-gray-500 uppercase tracking-wide self-center">
        {props.author.firstName} {props.author.lastName}
      </p>
    </div>
  );
};

export default Author;
